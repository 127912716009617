import { path } from "ramda";
import {
  ColorGradeOption,
  getBatteryHealthGrade,
  getCCAGrade,
  getConditionColorGrade,
  getFluidLevelColorGrade,
  getFluidPpmColorGrade,
  getPadsThicknessColorGrade,
  getRotorsThicknessColorGrade,
} from "../../PreJobChecklistV2/colorGradeHelpers";
import { PillOption } from "../fields/PillsSelect";
import { CUSTOM_INSPECTION_COLOR_GRADES, CUSTOM_INSPECTION_LEVELS, GOOD_CONDITION_OPTION, INSPECTION_COLOR_GRADES, INSPECTION_LEVELS, US_STATES } from "../../PreJobChecklistV2/constants";


export enum InputMode {
  none = "none",
  text = "text",
  tel = "tel",
  url = "url",
  email = "email",
  numeric = "numeric",
  decimal = "decimal",
  search = "search",
}

export enum InputType {
  input = "input",
  select = "select",
  checkbox = "checkbox",
  textArea = "textArea",
  vin = "vin",
}

export type FieldProps = {
  secondColorGradeParam?: any;
  options?: string[];
  customOptions?: PillOption[];
  parentName?: string;
  required?: boolean;
  name?: string;
  useAllRow?: boolean;
  className?: string;
  label?: string;
};
type BaseFieldProps = FieldProps & {
  type?: InputType;
};

export type EndAdornmentField = {
  text?: string;
  colorGrade?: (prop: string | string[], otherParam?: string) => ColorGradeOption;
  secondColorGradeParam?: string;
  colorGradeType?: "onlyValue" | "simpleFunction" | "functionWithTwoParams";
};

export type FieldObject = {
  itemType: "field";
  code: string;
  name: string;
  required?: boolean;
  inputMode?: InputMode;
  type: InputType;
  options?: string[] | { value: any; label: string }[];
  customOptions?: PillOption[];
  postOnChange?: (value, previousVal, setValue, setFieldValue) => void;
  onChange?: (event, setValue, setFieldValue) => void;
  multiple?: boolean;
  label?: string;
  endAdornment?: EndAdornmentField;
  placeholder?: string;
  useAllRow?: boolean;
  hasVinFile?: boolean;
  contactId?: string;
  jobId?: string;
  className?: string;
  disabled?: boolean;
};

export type FieldFunction = (props?: BaseFieldProps) => FieldObject;

const baseField = ({ name, parentName, type, ...restProps }: BaseFieldProps): FieldObject => ({
  itemType: "field",
  code: name,
  name: parentName ? `${parentName}.${name}` : name,
  type: type ?? InputType.input,
  inputMode: null,
  options: null,
  customOptions: null,
  multiple: null,
  endAdornment: {
    text: null,
    colorGrade: null,
    secondColorGradeParam: null,
    colorGradeType: "simpleFunction",
  },
  label: null,
  postOnChange: null,
  placeholder: null,
  hasVinFile: null,
  contactId: null,
  jobId: null,
  ...restProps,
});

export const odometer: FieldFunction = (props) => ({
  ...baseField({ ...props, name: "odometer" }),
  label: "Odomter",
  inputMode: InputMode.numeric,
});
export const ppm: FieldFunction = (props) => {
  let result = baseField({ ...props, name: "ppm" });
  result.label = "PPM";
  result.inputMode = InputMode.numeric;
  result.endAdornment.colorGrade = getFluidPpmColorGrade;
  return result;
};
export const level: FieldFunction = (props) => {
  let result = baseField({ ...props, name: "level", type: InputType.select });
  result.label = "Level";
  result.options = INSPECTION_LEVELS;
  result.customOptions = CUSTOM_INSPECTION_LEVELS;
  result.endAdornment.colorGrade = getFluidLevelColorGrade;
  return result;
};
export const pad: FieldFunction = (props) => {
  let result = baseField({ ...props, name: props.name });
  result.inputMode = InputMode.numeric;
  result.endAdornment.text = "mm";
  result.endAdornment.colorGrade = getPadsThicknessColorGrade;
  return result;
};
export const coldCrankingAmps: FieldFunction = (props) => {
  let result = baseField({ ...props, name: "coldCrankingAmps", label: "Cold Cranking Amps" });
  result.inputMode = InputMode.numeric;
  result.endAdornment.text = "CCA";
  result.endAdornment.colorGrade = getCCAGrade;
  result.endAdornment.colorGradeType = "functionWithTwoParams";
  result.endAdornment.secondColorGradeParam = props.secondColorGradeParam;
  return result;
};

export const withoutCCA: FieldFunction = (props) => ({
  ...baseField({ ...props, name: "withoutCCA", type: InputType.checkbox }),
  postOnChange: (value, _, __, setFieldValue) => {
    if (value) {
      setFieldValue("coldCrankingAmps", "");
    }
  },
  label: "Couldn't collect",
});

export const batteryHealth: FieldFunction = (props) => {
  let result = baseField({ ...props, name: "batteryHealth", label: "State of Health" });
  result.inputMode = InputMode.numeric;
  result.endAdornment.text = "%";
  result.endAdornment.colorGrade = getBatteryHealthGrade;
  return result;
};

export const withoutBatteryHealth: FieldFunction = (props) => ({
  ...baseField({ ...props, name: "withoutBatteryHealth", type: InputType.checkbox }),
  postOnChange: (value, _, __, setFieldValue) => {
    if (value) {
      setFieldValue("batteryHealth", "");
    }
  },
  label: "Couldn't collect",
});
export const innerPad: FieldFunction = (props) => {
  let result = pad({ ...props, name: props.name ?? "innerPad" });
  result.label = "Inner Pad";
  return result;
};
export const outerPad: FieldFunction = (props) => {
  let result = pad({ ...props, name: props.name ?? "outerPad" });
  result.label = "Outer Pad";
  return result;
};

export const condition: FieldFunction = (props) => {
  let result = baseField({ ...props, name: "condition", type: InputType.select });
  result.label = "Condition";
  result.postOnChange = (val, previousVal, setValue) => {
    if (previousVal?.includes(GOOD_CONDITION_OPTION) && val?.includes(GOOD_CONDITION_OPTION) && val?.length > 1) {
      setValue(val.filter((v) => v !== GOOD_CONDITION_OPTION));
      return;
    }

    if (val?.includes(GOOD_CONDITION_OPTION)) {
      setValue([GOOD_CONDITION_OPTION]);
      return;
    }
  };
  (result.multiple = true), (result.options = props.options), (result.endAdornment.colorGrade = getConditionColorGrade);
  return result;
};
export const thicknessInches: FieldFunction = (props) => ({
  ...baseField({ ...props, name: "thicknessInches" }),
  label: "Thickness",
  endAdornment: {
    text: "inches",
    colorGrade: getRotorsThicknessColorGrade,
    colorGradeType: "functionWithTwoParams",
    secondColorGradeParam: props.secondColorGradeParam,
  },
});
export const usesRearShoesAndDrums: FieldFunction = (props) => ({
  ...baseField({ ...props, name: "usesRearShoesAndDrums", type: InputType.checkbox }),
  postOnChange: (value, _, __, setFieldValue) => {
    if (value) {
      setFieldValue("rearPads", {
        innerPadThickness: "",
        outerPadThickness: "",
        padsThickness: "",
        condition: [],
      });
      setFieldValue("rearRotors", {
        thicknessInches: "",
        condition: [],
      });
    } else {
      setFieldValue("rearShoes", {
        colorGrade: "",
        condition: [],
      });
      setFieldValue("rearDrums", {
        colorGrade: "",
        condition: [],
      });
    }
  },
  label: "Rear Axle is Shoes and Drums",
});
export const privateNotes: FieldFunction = (props) => ({
  ...baseField({ ...props, name: "privateNotes", type: InputType.textArea }),
  placeholder: "Internal viewable by Support, Ops and Sales.",
});
export const colorGrade: FieldFunction = (props) => ({
  ...baseField({ ...props, name: "colorGrade", type: InputType.select }),
  label: "Color Grade",
  options: INSPECTION_COLOR_GRADES,
  customOptions: CUSTOM_INSPECTION_COLOR_GRADES,
  endAdornment: {
    colorGradeType: "onlyValue",
  },
});
export const notes: FieldFunction = (props) => ({
  ...baseField({ ...props, name: "notes", type: InputType.textArea }),
  label: "Notes",
});
export const licensePlate: FieldFunction = (props) => ({
  ...baseField({ ...props, name: "licensePlate" }),
  label: "License Plate",
});
export const licensePlateState: FieldFunction = (props) => ({
  ...baseField({ ...props, name: "licensePlateState" }),
  type: InputType.select,
  options: US_STATES,
  label: "License Plate",
  onChange: (event, setValue) => {
    const value = path<string>(["target", "value"], event);
    setValue(value);
  },
});
export const updloadVin: FieldFunction = (props) => ({
  ...baseField({ ...props, name: "licensePlateState", type: InputType.vin }),
});
