import { indexBy } from "ramda";
import { ChecklistInspectedServiceOption } from "./types";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import { ColorGradeOption } from "./colorGradeHelpers";
import {
  CheckListV2BrakeFluidInfo,
  CheckListV2DrumsInfo,
  CheckListV2InspectedServicesItem,
  CheckListV2PadsInfo,
  CheckListV2RotorsInfo,
  CheckListV2ShoesInfo,
} from "../../generated/nest-graphql";

export const POSSIBLE_INSPECTED_SERVICES_LIST: {
  name: ChecklistInspectedServiceOption;
  label: string;
}[] = [
  {
    name: "frontLeftCaliper",
    label: "Front Left Caliper",
  },
  {
    name: "frontRightCaliper",
    label: "Front Right Caliper",
  },
  {
    name: "rearLeftCaliper",
    label: "Rear Left Caliper",
  },
  {
    name: "rearRightCaliper",
    label: "Rear Right Caliper",
  },
  {
    name: "frontLeftHubAssemblyBearing",
    label: "Front Left Hub Assembly/Bearing",
  },
  {
    name: "frontRightHubAssemblyBearing",
    label: "Front Right Hub Assembly/Bearing",
  },
  {
    name: "rearLeftHubAssemblyBearing",
    label: "Rear Left Hub Assembly/Bearing",
  },
  {
    name: "rearRightHubAssemblyBearing",
    label: "Rear Right Hub Assembly/Bearing",
  },
];

export const POSSIBLE_INSPECTED_SERVICES_INDEXED = indexBy((service) => service.name, POSSIBLE_INSPECTED_SERVICES_LIST);

export const GOOD_CONDITION_OPTION = "Good condition";
export const BAD_CONDITION_OPTION = "Bad condition";

export const PADS_CONDITION_OPTIONS = [
  GOOD_CONDITION_OPTION,
  "Glazing",
  "Uneven Wear",
  "Rust",
  "Cracks",
  "Discoloration",
  "Grinding",
];

export const CUSTOM_PADS_CONDITION_OPTIONS = [
  {
    value: GOOD_CONDITION_OPTION,
    icon: CheckCircleIcon,
    color: "bg-green-500",
  },
  {
    value: BAD_CONDITION_OPTION,
    subOptions: ["Glazing", "Uneven Wear", "Rust", "Cracks", "Discoloration", "Grinding"],
    icon: WarningIcon,
    color: "bg-red-500",
  },
];

export const ROTORS_CONDITION_OPTIONS = [
  GOOD_CONDITION_OPTION,
  "Grooving",
  "Uneven Wear/Rust lip",
  "Rust",
  "Cracks",
  "Heat spots",
  "Grinding",
  "Warped",
  "Glazing",
];

export const CUSTOM_ROTORS_CONDITION_OPTIONS = [
  {
    value: GOOD_CONDITION_OPTION,
    icon: CheckCircleIcon,
    color: "bg-green-500",
  },
  {
    value: BAD_CONDITION_OPTION,
    subOptions: ["Grooving", "Uneven Wear/Rust lip", "Rust", "Cracks", "Heat spots", "Grinding", "Warped", "Glazing"],
    icon: WarningIcon,
    color: "bg-red-500",
  },
];

export const SHOES_CONDITIONS_OPTIONS = [
  GOOD_CONDITION_OPTION,
  "Squeaking/rubbing/scraping noises during road test",
  "Extreme rust",
  "Out-of-round drum",
];

export const CUSTOM_SHOES_CONDITIONS_OPTIONS = [
  {
    value: GOOD_CONDITION_OPTION,
    icon: CheckCircleIcon,
    color: "bg-green-500",
  },
  {
    value: BAD_CONDITION_OPTION,
    subOptions: ["Squeaking/rubbing/scraping noises during road test", "Extreme rust", "Out-of-round drum"],
    icon: WarningIcon,
    color: "bg-red-500",
  },
];

export const DRUMS_CONDITIONS_OPTIONS = [
  GOOD_CONDITION_OPTION,
  "Squeaking/rubbing/scraping noises during road test",
  "Extreme rust",
  "Out-of-round drum",
];

export const CUSTOM_DRUMS_CONDITIONS_OPTIONS = [
  {
    value: GOOD_CONDITION_OPTION,
    icon: CheckCircleIcon,
    color: "bg-green-500",
  },
  {
    value: BAD_CONDITION_OPTION,
    subOptions: ["Squeaking/rubbing/scraping noises during road test", "Extreme rust", "Out-of-round drum"],
    icon: WarningIcon,
    color: "bg-red-500",
  },
];

export const INSPECTION_COLOR_GRADES = ["Red", "Yellow", "Green"];

export const CUSTOM_INSPECTION_COLOR_GRADES = [
  {
    value: "Red",
    icon: ErrorIcon,
    color: "bg-red-500",
  },
  {
    value: "Yellow",
    icon: WarningIcon,
    color: "bg-yellow-500",
  },
  {
    value: "Green",
    icon: CheckCircleIcon,
    color: "bg-green-500",
  },
];

export const INSPECTION_LEVELS = ["Full", "Not full"];

export const CUSTOM_INSPECTION_LEVELS = [
  {
    value: "Full",
    color: "bg-gray-400",
  },
  {
    value: "Not full",
    color: "bg-gray-400",
  },
];

export const US_STATES = [
  { value: "Alabama", label: "AL" },
  { value: "Alaska", label: "AK" },
  { value: "Arizona", label: "AZ" },
  { value: "Arkansas", label: "AR" },
  { value: "California", label: "CA" },
  { value: "Colorado", label: "CO" },
  { value: "Connecticut", label: "CT" },
  { value: "Delaware", label: "DE" },
  { value: "Florida", label: "FL" },
  { value: "Georgia", label: "GA" },
  { value: "Hawaii", label: "HI" },
  { value: "Idaho", label: "ID" },
  { value: "Illinois", label: "IL" },
  { value: "Indiana", label: "IN" },
  { value: "Iowa", label: "IA" },
  { value: "Kansas", label: "KS" },
  { value: "Kentucky", label: "KY" },
  { value: "Louisiana", label: "LA" },
  { value: "Maine", label: "ME" },
  { value: "Maryland", label: "MD" },
  { value: "Massachusetts", label: "MA" },
  { value: "Michigan", label: "MI" },
  { value: "Minnesota", label: "MN" },
  { value: "Mississippi", label: "MS" },
  { value: "Missouri", label: "MO" },
  { value: "Montana", label: "MT" },
  { value: "Nebraska", label: "NE" },
  { value: "Nevada", label: "NV" },
  { value: "New Hampshire", label: "NH" },
  { value: "New Jersey", label: "NJ" },
  { value: "New Mexico", label: "NM" },
  { value: "New York", label: "NY" },
  { value: "North Carolina", label: "NC" },
  { value: "North Dakota", label: "ND" },
  { value: "Ohio", label: "OH" },
  { value: "Oklahoma", label: "OK" },
  { value: "Oregon", label: "OR" },
  { value: "Pennsylvania", label: "PA" },
  { value: "Rhode Island", label: "RI" },
  { value: "South Carolina", label: "SC" },
  { value: "South Dakota", label: "SD" },
  { value: "Tennessee", label: "TN" },
  { value: "Texas", label: "TX" },
  { value: "Utah", label: "UT" },
  { value: "Vermont", label: "VT" },
  { value: "Virginia", label: "VA" },
  { value: "Washington", label: "WA" },
  { value: "West Virginia", label: "WV" },
  { value: "Wisconsin", label: "WI" },
  { value: "Wyoming", label: "WY" },
];

export const VIN_FILE_CATEGORY = "VIN";

interface ServiceObject {
  title: string;
  services?: string[];
  isCaliper?: boolean;
}

export type InspectedService = (
  | CheckListV2BrakeFluidInfo
  | CheckListV2PadsInfo
  | CheckListV2RotorsInfo
  | CheckListV2DrumsInfo
  | CheckListV2InspectedServicesItem
  | CheckListV2ShoesInfo
  | {
      __typename: "battery";
      CCA: number;
      coldCrankingAmps: string;
      batteryHealth: string;
    }
) & {
  config: ServiceObject;
  status: ColorGradeOption;
};

export const mappedSnapshotData: [string[], ServiceObject][] = [
  [
    ["frontPads", "frontPadsV2"],
    {
      title: "Front Pads",
      services: ["616060f7cafe02eccc346a2f"],
    },
  ],
  [
    ["frontRotors"],
    {
      title: "Front Rotors",
      services: ["616060f7cafe02eccc346a3b", "616060f7cafe02eccc346a43"],
    },
  ],
  [
    ["rearPads", "rearPadsV2"],
    {
      title: "Rear Pads",
      services: ["616060f7cafe02eccc346a35"],
    },
  ],
  [
    ["rearRotors"],
    {
      title: "Rear Rotors",
      services: ["616060f7cafe02eccc346a3f", "616060f7cafe02eccc346a4a"],
    },
  ],
  [
    ["brakeFluid"],
    {
      title: "Brake Fluid",
      services: ["6336023ce45d8f01f2bd14a6", "63360307a14bd90f43081f98", "659dac77894649c5ac5929c6"],
    },
  ],
  [
    ["rearShoes"],
    {
      title: "Rear Shoes",
      services: ["616060f7cafe02eccc346a58"],
    },
  ],
  [
    ["rearDrums"],
    {
      title: "Rear Drums",
      services: ["616060f7cafe02eccc346a51"],
    },
  ],
  [
    ["frontLeftCaliper"],
    {
      title: "Front Left Caliper",
      isCaliper: true,
    },
  ],
  [
    ["frontRightCaliper"],
    {
      title: "Front Right Caliper",
      isCaliper: true,
    },
  ],
  [
    ["rearLeftCaliper"],
    {
      title: "Rear Left Caliper",
      isCaliper: true,
    },
  ],
  [
    ["rearRightCaliper"],
    {
      title: "Rear Right Caliper",
      isCaliper: true,
    },
  ],
  [
    ["frontLeftHubAssemblyBearing"],
    {
      title: "Front Left Hub Assembly/Bearing",
    },
  ],
  [
    ["frontRightHubAssemblyBearing"],
    {
      title: "Front Right Hub Assembly/Bearing",
    },
  ],
  [
    ["rearLeftHubAssemblyBearing"],
    {
      title: "Rear Left Hub Assembly/Bearing",
    },
  ],
  [
    ["rearRightHubAssemblyBearing"],
    {
      title: "Rear Right Hub Assembly/Bearing",
    },
  ],
  [
    ["battery"],
    {
      title: "Battery",
    },
  ],
];
