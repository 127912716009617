import * as React from "react";
import { PhotoForm, FileFormValues} from "./PhotoForm";

export const UploadPhotoSection: React.FC<{
  contactId: string;
  jobId: string;
  name: string;
  fieldName: string;
  optionsLabel?: string[];
  initialValues: FileFormValues;
  onSubmit: any;
}> = ({ contactId, initialValues, onSubmit, name, jobId, fieldName, optionsLabel }) => {
  return (
    <div>
      <PhotoForm contactId={contactId} jobId={jobId} name={name} initialValues={initialValues} onSubmit={onSubmit} fieldName={fieldName} optionsLabel={optionsLabel}/>
    </div>
  );
}