import { useDropzone } from "react-dropzone";
import { head } from "ramda";
import { useCallback } from "react";

type UseS3UploadInput = {
  contactId: string;
  onUploadStart: (img: any) => Promise<string>;
  onUploadDone: ({ url, fileName }: { url: string; fileName: string }) => void;
  onError: any;
  myFiles;
  setMyFiles;
};

export function useS3Upload({
  onUploadStart,
  onUploadDone,
  onError,
  contactId,
  myFiles,
  setMyFiles,
}: UseS3UploadInput) {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const fileToUpload = head<any>(acceptedFiles);
      console.log("[acceptedFiles]",acceptedFiles);
      console.log("[fileToUpload]",fileToUpload)
      const presignedUploadUrl = await onUploadStart(fileToUpload.name);
      if(setMyFiles){
        setMyFiles(acceptedFiles);
      }
      // Upload the image to our pre-signed URL.
      const response = await fetch(
        new Request(presignedUploadUrl, {
          method: "PUT",
          body: fileToUpload,
          headers: new Headers({
            "Content-Type": "*",
          }),
        })
      );
      if (response.status !== 200) {
        onError();
        return;
      }
      const url = `${
        process.env.REACT_APP_S3_MEDIA_URL
      }/contacts/${contactId}/${encodeURI(fileToUpload.name)}`;
      await onUploadDone({ fileName: fileToUpload.name, url });
      // Let the caller know that the upload is done, so that it can send the URL
      // to the backend again, persisting it to the database.
    },
    [contactId, onError, onUploadDone, onUploadStart, setMyFiles]
  );

  return useDropzone({
    onDrop: onDrop,
    multiple: false,
  });
}


export function useSimpleS3Upload({
  onUploadStart,
  onUploadDone,
  onError,
  contactId,
  myFiles,
  setMyFiles,
}: UseS3UploadInput) {
  const onUpload = useCallback(
    async (acceptedFiles) => {
      const fileToUpload = head<any>(acceptedFiles);
      const presignedUploadUrl = await onUploadStart(fileToUpload.name);
      if(setMyFiles){
        setMyFiles(acceptedFiles);
      }
      // Upload the image to our pre-signed URL.
      const response = await fetch(
        new Request(presignedUploadUrl, {
          method: "PUT",
          body: fileToUpload,
          headers: new Headers({
            "Content-Type": "*",
          }),
        })
      );
      if (response.status !== 200) {
        onError();
        return;
      }
      const url = `${
        process.env.REACT_APP_S3_MEDIA_URL
      }/contacts/${contactId}/${encodeURI(fileToUpload.name)}`;
      await onUploadDone({ fileName: fileToUpload.name, url });
      // Let the caller know that the upload is done, so that it can send the URL
      // to the backend again, persisting it to the database.
    },
    [contactId, onError, onUploadDone, onUploadStart, setMyFiles]
  );

  return {
    onUpload: onUpload,
  };
}
