import { complement, equals, find, isNil, keys, path, pathEq, pathOr, pipe, pluck, prop, propEq } from "ramda";
import React, { useEffect, useState } from "react";
import { AppBar } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { GET_JOB } from "../../graphql/queries/getJob";
import {
  Contact,
  CreateInvoiceInput,
  Item,
  Job,
  Mutation,
  MutationCreateInvoiceArgs,
  MutationUpdateInvoiceArgs,
  MutationUpdateJobArgs,
  PossibleEjiService,
  Query,
  QueryGetJobArgs,
  QueryGetPossibleServiceArgs,
} from "../../generated/nest-graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Layout } from "../../components/Layout";
import { JobStatusSection } from "../../components/Jobs/JobStatusSection";
import { JobDetailsForm, JobDetailsFormValues } from "../../components/Forms/JobDetailsForm";
import { cleanObject, objectDiff } from "../../lib/functions";
import {
  preJobCheckListInitialValues,
  preJobCheckListV2InitialValues,
  updateJobDetailsSpec,
} from "../../components/Jobs/jobDetailsSpec";
import { UPDATE_JOB } from "../../graphql/mutations/updateJob";
import { useHistory } from "react-router-dom";
import { useEntityId } from "../../hooks/useEntityId";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import { UpdateInvoiceDetailsFormValues } from "../../components/Forms/UpdateInvoiceDetailsForm";
import { CREATE_INVOICE } from "../../graphql/mutations/createInvoice";
import { GET_PRODUCTS } from "../../graphql/queries/getProducts";
import { updateInvoiceDetailsSpec } from "../../components/Invoices/invoiceDetailsSpec";
import { UPDATE_INVOICE } from "../../graphql/mutations/updateInvoice";
import { DEFAULT_SERVICE_CATALOGUE_USED, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import { GET_POSSIBLE_SERVICE } from "../../graphql/queries/getPossibleService";
import {
  ejiDiscountsToFormValuesSpec,
  ejiPriceInfoToFormValue,
  ejiServicesToFormValuesSpec,
  possibleEJIServicesToFormValuesSpec,
} from "../../specs/ejiServicesSpec";

const JobDetailsAppBar: React.FC<{
  addInvoiceInitialValues: UpdateInvoiceDetailsFormValues;
  message: string;
  phoneNumber: string;
  jobNumber: string;
  jobId: string;
  status: string;
  invoiceId: string;
  contact: Contact;
}> = ({ jobId, jobNumber, status, invoiceId, addInvoiceInitialValues, message, phoneNumber, contact }) => {
  const { goBack } = useHistory();
  return (
    <AppBar position={"sticky"}>
      <Toolbar>
        <IconButton onClick={goBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">{`Job: ${jobNumber}`}</Typography>
        <Box />
      </Toolbar>
      <JobStatusSection
        message={message}
        phoneNumber={phoneNumber}
        jobId={jobId}
        status={status}
        invoiceId={invoiceId}
        addInvoiceInitialValues={addInvoiceInitialValues}
        contact={contact}
      />
    </AppBar>
  );
};

const JobSummaryPage = () => {
  const [selectedServices, setSelectedServices] = useState({});
  const jobId = useEntityId();
  const dispatch = useDispatch();
  const { data } = useQuery<Query, QueryGetJobArgs>(GET_JOB, {
    variables: {
      id: jobId,
    },
    fetchPolicy: "network-only",
    returnPartialData: true,
  });
  const products = useQuery<Query, {}>(GET_PRODUCTS);
  const serviceCallService = useQuery<Query, QueryGetPossibleServiceArgs>(GET_POSSIBLE_SERVICE, {
    variables: {
      getPossibleServiceInput: {
        name: "Service Call",
      },
    },
    returnPartialData: true,
  });
  const [createInvoice] = useMutation<Mutation, MutationCreateInvoiceArgs>(CREATE_INVOICE);
  const [updateInvoice] = useMutation<Mutation, MutationUpdateInvoiceArgs>(UPDATE_INVOICE);
  const [updateJob] = useMutation<Mutation, MutationUpdateJobArgs>(UPDATE_JOB);
  let initialValues = {} as JobDetailsFormValues;
  useEffect(() => {
    if (initialValues.services) {
      let selected = {};
      initialValues.services.forEach((el, index) => {
        if (el.inInvoice) {
          selected[index] = true;
        }
      });

      setSelectedServices(selected);
    }
  }, [data]);

  if (!prop("getJob", data)) return null;
  const job: Job = data.getJob;
  const { market, contact, serviceLocation, items, vehicleInfo, privateNotes } = job;
  const hasPreJobChecklist = propEq("preJobCheckList", null);
  const hasPreJobChecklistV2 = propEq("preJobCheckListV2", null);
  initialValues = {
    type: job.type,
    serviceCallReason: pathOr("", ["serviceCallReason"], job),
    serviceCallReasonExtraInfo: pathOr("", ["serviceCallReasonExtraInfo"], job),
    warrantyCheckReason: pathOr("", ["warrantyCheckReason"], job),
    warrantyCheckReasonExtraInfo: pathOr("", ["warrantyCheckReasonExtraInfo"], job),
    warrantyCheckTechReason: pathOr("", ["warrantyCheckTechReason"], job),
    warrantyCheckTechReasonExtraInfo: pathOr("", ["warrantyCheckTechReasonExtraInfo"], job),
    appointmentId: null,
    taxable: job?.taxable,
    status: job.status,
    market: job.market,
    jobName: job.jobName,
    viocStore: job?.viocStore,
    locationType: job?.locationType,
    vehicleId: job.vehicleId,
    year: pathOr("", ["vehicleInfo", "year"], job),
    vin: pathOr("", ["vehicleInfo", "vin"], job),
    rearPadLife: pathOr("", ["vehicleInfo", "rearPadLife"], job),
    odometer: pathOr("", ["vehicleInfo", "odometer"], job),
    model: pathOr("", ["vehicleInfo", "model"], job),
    make: pathOr("", ["vehicleInfo", "make"], job),
    licensePlate: pathOr("", ["vehicleInfo", "licensePlate"], job),
    frontPadLife: pathOr("", ["vehicleInfo", "frontPadLife"], job),
    extraInfo: pathOr("", ["vehicleInfo", "extraInfo"], job),
    wheelTorque: pathOr(null, ["vehicleInfo", "wheelTorque"], job),
    symptoms: pathOr("", ["vehicleInfo", "symptoms"], job),
    frontBrakeSymptoms: pathOr([], ["vehicleInfo", "frontBrakeSymptoms"], job),
    rearBrakeSymptoms: pathOr([], ["vehicleInfo", "rearBrakeSymptoms"], job),
    additionalNotes: pathOr("", ["vehicleInfo", "additionalNotes"], job),
    customerExpectation: pathOr("", ["vehicleInfo", "customerExpectation"], job),
    partsOrderNumber: pathOr("", ["partsInfo", "partsOrderNumber"], job),
    partsOrdered: pathOr(false, ["partsInfo", "partsOrdered"], job),
    partsLocation: pathOr("", ["partsInfo", "partsLocation"], job),
    partsNotes: pathOr("", ["partsInfo", "partsNotes"], job),
    preJobCheckList: !hasPreJobChecklist(job as any) ? preJobCheckListInitialValues(job) : null,
    preJobCheckListV2: !hasPreJobChecklistV2(job as any) ? preJobCheckListV2InitialValues(job) : null,
    serviceLocation: job.serviceLocation,
    contact: job.contact,
    description: job.description,
    // @ts-ignore
    items: job.items,
    services: ejiServicesToFormValuesSpec(pathOr([], ["services"], job)),
    discounts: ejiDiscountsToFormValuesSpec(pathOr([], ["priceInfo", "discounts"], job)),
    promoCodes: pathOr([], ["priceInfo", "promoCodes"], job),
    priceInfo: ejiPriceInfoToFormValue(pathOr({}, ["priceInfo"], job)),
    serviceCatalogueUsed: pathOr(DEFAULT_SERVICE_CATALOGUE_USED, ["serviceCatalogueUsed"], job),
    privateNotes: job.privateNotes,
    serviceLocationNotes: job.serviceLocationNotes,
    partsOrderingIssuesSurveys: pathOr([], ["partsOrderingIssuesSurveys"], job),
  };
  const jobNumber: string = prop("jobNumber", job);
  const invoiceInitialValues: UpdateInvoiceDetailsFormValues = {
    market,
    jobNumber,
    status: "Draft",
    taxable: job?.taxable,
    contact,
    serviceLocation,
    estimate: path(["estimate", "id"], job),
    technician: path(["appointment", "technician"], job),
    items,
    services: ejiServicesToFormValuesSpec(pathOr([], ["services"], job)).map((service, index) => {
      if (selectedServices[index]) {
        return {
          ...service,
          inInvoice: selectedServices[index],
        };
      }
      return service;
    }),
    discounts: ejiDiscountsToFormValuesSpec(pathOr([], ["priceInfo", "discounts"], job)),
    promoCodes: pathOr([], ["priceInfo", "promoCodes"], job),
    priceInfo: ejiPriceInfoToFormValue(pathOr({}, ["priceInfo"], job)),
    serviceCatalogueUsed: pathOr(DEFAULT_SERVICE_CATALOGUE_USED, ["serviceCatalogueUsed"], job),
    issuedDate: new Date(),
    dueDate: new Date(),
    ...vehicleInfo,
    privateNotes,
    jobId,
    preJobCheckList: !hasPreJobChecklist(job as any) ? preJobCheckListInitialValues(job) : null,
    preJobCheckListV2: !hasPreJobChecklistV2(job as any) ? preJobCheckListV2InitialValues(job) : null,
    serviceFollowUp: {
      followUpNeeded: false,
      outreachTimeline: null,
      outreachDate: null,
      outreachNotes: null,
    },
  };
  const firstName = path(["contact", "firstName"], job);
  const technicianFirstName = path(["appointment", "technician", "firstName"], job);
  const appointment = prop("appointment", job);
  const invoiceId = path<string>(["invoice", "id"], job);
  const jobStatus = prop("status", job);
  // @ts-ignore
  const fileNames: string[] = pipe(pathOr([], ["contact", "files"]), pluck("fileNames"))(job);
  const createServiceCallInvoiceInput = () => {
    const serviceCallProduct = pipe(prop("getProducts"), find(pathEq(["name"], "Service Call")))(products.data);
    const serviceJobItem: Item = {
      ...serviceCallProduct,
      isInEstimate: false,
      product: path(["id"], serviceCallProduct),
    };
    const serviceCall = path<PossibleEjiService>(["data", "getPossibleService"])(serviceCallService);
    const serviceCallFormValue = possibleEJIServicesToFormValuesSpec([serviceCall]);
    const invoiceValues: UpdateInvoiceDetailsFormValues =
      invoiceInitialValues.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES
        ? {
            ...invoiceInitialValues,
            items: [serviceJobItem],
            services: [...serviceCallFormValue],
          }
        : {
            ...invoiceInitialValues,
            items: [serviceJobItem],
          };
    return pipe(updateInvoiceDetailsSpec, cleanObject)(invoiceValues);
  };
  const onSubmit = async (values: JobDetailsFormValues) => {
    const diff = objectDiff(values, initialValues);
    const type = prop("type", diff);
    const exists = complement(isNil);
    // create or update an invoice for a service type job
    if (exists(type) && equals(type, "Service Call")) {
      const input = await createServiceCallInvoiceInput();
      if (isNil(invoiceId)) {
        await createInvoice({
          variables: {
            createInvoiceInput: input as CreateInvoiceInput,
          },
          update: (cache, { data: { createInvoice } }) => {
            const { getJob } = cache.readQuery<Query, QueryGetJobArgs>({
              variables: {
                id: jobId,
              },
              query: GET_JOB,
            });
            const objectToPlace = {
              getJob: {
                ...getJob,
                invoice: createInvoice,
              },
            };
            cache.writeQuery({
              query: GET_JOB,
              variables: {
                id: jobId,
              },
              data: objectToPlace,
            });
          },
        });
      } else {
        await updateInvoice({
          variables: {
            id: invoiceId,
            updateInvoiceInput: input,
          },
        });
      }
    }
    // @ts-ignore
    const updates = pipe(updateJobDetailsSpec, cleanObject)(diff);
    if (keys(updates).length) {
      await updateJob({
        variables: {
          id: jobId,
          updateJobInput: updates,
        },
      });
      showSuccessAlert(dispatch, "Success");
    }
  };

  const toggleSelectedServices = (index: number) => {
    setSelectedServices((selectedServices) => {
      const selected = { ...selectedServices };
      selected[index] = !selected[index];

      return selected;
    });
  };

  return (
    <Layout
      appBar={
        <JobDetailsAppBar
          addInvoiceInitialValues={invoiceInitialValues}
          jobNumber={jobNumber}
          jobId={jobId}
          phoneNumber={path(["contact", "phoneNumber"], job)}
          message={`Hi ${firstName}, This is ${technicianFirstName} with NuBrakes. I'm on my way!`}
          status={jobStatus}
          invoiceId={invoiceId}
          contact={contact}
        />
      }
    >
      <JobDetailsForm
        jobId={jobId}
        job={job}
        onSubmit={onSubmit}
        initialValues={initialValues}
        fileNames={fileNames}
        appointment={appointment}
        toggleSelectedServices={toggleSelectedServices}
      />
    </Layout>
  );
};

export default JobSummaryPage;
