import React from "react";
import { CheckCircle, Warning, Error } from "@material-ui/icons";
import { GOOD_CONDITION_OPTION, InspectedService, mappedSnapshotData } from "./constants";
import { Job } from "../../generated/nest-graphql";

export type ColorGradeOption = "Red" | "Yellow" | "Green";

export const ColorGradeIcon: React.FC<{
  colorGrade?: ColorGradeOption | null;
  className?: string;
}> = React.memo(function ColorGradeIcon({ colorGrade, className }) {
  if (colorGrade === "Green") {
    return (
      <div className={className}>
        {" "}
        <CheckCircle fontSize="small" style={{ color: "#00C853" }} />
      </div>
    );
  }

  if (colorGrade === "Yellow") {
    return (
      <div className={className}>
        <Warning fontSize="small" style={{ color: "#FFD600" }} />
      </div>
    );
  }

  if (colorGrade === "Red") {
    return (
      <div className={className}>
        <Error fontSize="small" style={{ color: "#D50000" }} />
      </div>
    );
  }

  return null;
});

// Shared
export const getConditionColorGrade = (condition: string[]): ColorGradeOption | null => {
  if (!Array.isArray(condition)) return null;
  if (condition.length === 0) return null;
  if (condition.includes(GOOD_CONDITION_OPTION)) return "Green";
  return "Red";
};

// Fluid
export const getFluidPpmColorGrade = (ppm: string): ColorGradeOption | null => {
  if (!ppm) return null;
  const ppmNumber = Number(ppm);
  if (ppmNumber < 30) {
    return "Green";
  } else if (ppmNumber < 200) {
    return "Yellow";
  } else if (ppmNumber >= 200) {
    return "Red";
  }
  return null;
};
export const getFluidLevelColorGrade = (level: string): ColorGradeOption | null => {
  if (level === "Full") return "Green";
  if (level === "Not full") return "Red";
  return null;
};

// Pads
export const getPadsThicknessColorGrade = (thickness: string): ColorGradeOption | null => {
  if (!thickness) return null;
  const thicknessNumber = Number(thickness);
  if (Number.isNaN(thicknessNumber)) return null;
  if (thicknessNumber <= 4) {
    return "Red";
  } else if (thicknessNumber <= 6) {
    return "Yellow";
  } else if (thicknessNumber > 6) {
    return "Green";
  }
  return null;
};

// Battery Health
export const getCCAGrade = (coldCrankingAmps: string, CCA: string): ColorGradeOption | null => {
  if (!coldCrankingAmps || !CCA) return null;
  const coldCrankingAmpsNumber = Number(coldCrankingAmps);
  const CCANumber = Number(CCA);
  const result = (coldCrankingAmpsNumber * 100) / CCANumber;
  if (Number.isNaN(result)) return null;
  if (result < 50) {
    return "Red";
  } else if (result >= 50 && result <= 60) {
    return "Yellow";
  } else if (result > 60) {
    return "Green";
  }
  return null;
};

export const getBatteryHealthGrade = (batteryHealth: string): ColorGradeOption | null => {
  if (!batteryHealth) return null;
  const batteryHealthNumber = Number(batteryHealth);
  if (Number.isNaN(batteryHealthNumber)) return null;
  if (batteryHealthNumber < 65) {
    return "Red";
  } else if (batteryHealthNumber >= 65 && batteryHealthNumber < 80) {
    return "Yellow";
  } else if (batteryHealthNumber >= 80) {
    return "Green";
  }
  return null;
};

// Rotors
export const getRotorsThicknessColorGrade = (
  thickness: string | null,
  discardThickness: string | null = null
): ColorGradeOption | null => {
  if (!thickness) return null;
  const thicknessNumber = Number(thickness);
  if (Number.isNaN(thicknessNumber)) return null;

  if (!discardThickness) return null;
  const discardThicknessNumber = Number(discardThickness);
  if (Number.isNaN(discardThicknessNumber)) return null;

  if (thicknessNumber <= discardThicknessNumber) {
    return "Red";
  } else {
    return "Green";
  }
};

export const ColorGradeMap = (colorGrade?: ColorGradeOption[] | null): ColorGradeOption => {
  if (colorGrade.includes("Red")) return "Red";
  if (colorGrade.includes("Yellow")) return "Yellow";
  return "Green";
};

interface GetStatusProps {
  thickness?: string | number;
  condition?: string[];
  colorGrade?: ColorGradeOption;
  CCA?: number;
  job: Job;
  typeName: string;
  coldCrankingAmps?: string;
  batteryHealth?: string;
}

export const getStatus = ({
  thickness,
  condition,
  coldCrankingAmps,
  CCA,
  colorGrade,
  batteryHealth,
  job,
  typeName,
}: GetStatusProps) => {
  let thicknessColorGrade, conditionColorGrade, CCAColorGrade, batteryHealthColorGrade;
  if (thickness) {
    if (typeName === "CheckListV2BrakeFluidInfo") {
      thicknessColorGrade = isNaN(Number(thickness))
        ? getFluidLevelColorGrade(thickness as string)
        : getFluidPpmColorGrade(thickness as string);
    }
    if (typeName === "CheckListV2RotorsInfo") {
      thicknessColorGrade = getRotorsThicknessColorGrade(
        thickness as string,
        job?.vehicleInfo?.frontRotorsDiscardThicknessInches?.toString()
      );
    }
    if (["CheckListV2PadsInfo", "BrakePadsListItem"].includes(typeName)) {
      thicknessColorGrade = getPadsThicknessColorGrade(thickness as string);
    }
  }
  if (condition) conditionColorGrade = getConditionColorGrade(condition);
  if (coldCrankingAmps && CCA) CCAColorGrade = getCCAGrade(coldCrankingAmps, CCA.toString());
  if (batteryHealth) batteryHealthColorGrade = getBatteryHealthGrade(batteryHealth);

  return ColorGradeMap([thicknessColorGrade, conditionColorGrade, colorGrade, CCAColorGrade, batteryHealthColorGrade]);
};

export const groupServicesByGrade = (
  job?: Job
): {
  immediatly?: InspectedService[];
  soon?: InspectedService[];
  good?: InspectedService[];
} => {
  const checkList = job?.preJobCheckListV2;
  if (!checkList) return {};
  const immediatlyServices = [];
  const soonServices = [];
  const goodServices = [];
  Object.entries({
    ...checkList,
    ...checkList.inspectedServices,
    battery: {
      __typename: "battery" as const,
      CCA: job?.vehicle?.CCA,
      coldCrankingAmps: checkList.coldCrankingAmps,
      batteryHealth: checkList.batteryHealth,
    },
  }).forEach(([key, service]) => {
    if (
      typeof service === "string" ||
      typeof service === "boolean" ||
      service?.__typename === "CheckListV2InspectedServices"
    )
      return;

    const config = mappedSnapshotData.find(([itemKey]) => itemKey.includes(key))?.[1];
    if (!config) return;
    let thickness, colorGrade, condition, CCA, coldCrankingAmps, batteryHealth;
    if (service.__typename === "battery" && service.batteryHealth != null) {
      batteryHealth = service.batteryHealth;
    } else if (
      service.__typename === "CheckListV2PadsInfo" &&
      (service.condition?.length || service.innerPadThickness || service.outerPadThickness || service.padsThickness)
    ) {
      thickness = [service.innerPadThickness, service.outerPadThickness, service.padsThickness]
        .filter((e) => e)
        .sort()[0];
      condition = service.condition;
    } else if (
      service.__typename === "CheckListV2RotorsInfo" &&
      (service.condition?.length || service.thicknessInches)
    ) {
      thickness = service.thicknessInches;
      condition = service.condition;
    } else if (
      service.__typename === "CheckListV2BrakeFluidInfo" &&
      (service.colorGrade || service.level || service.ppm)
    ) {
      thickness = service.level;
      colorGrade = service.colorGrade;
    } else if (
      (service.__typename === "CheckListV2DrumsInfo" || service.__typename === "CheckListV2ShoesInfo") &&
      (service.colorGrade || service.condition?.length)
    ) {
      colorGrade = service.colorGrade;
      condition = service.condition;
    } else if (service.__typename === "CheckListV2InspectedServicesItem" && (service.colorGrade || service.notes)) {
      colorGrade = service.colorGrade;
      thickness = service.notes;
    } else return;
    const status = getStatus({
      job,
      thickness,
      CCA,
      coldCrankingAmps,
      colorGrade,
      condition,
      batteryHealth,
      typeName: service.__typename,
    });
    const newService = {
      ...service,
      config,
      status,
    };
    if (newService.status === "Red") {
      immediatlyServices.push(newService);
    } else if (newService.status === "Yellow") {
      soonServices.push(newService);
    } else {
      goodServices.push(newService);
    }
  });
  return {
    immediatly: immediatlyServices,
    soon: soonServices,
    good: goodServices,
  };
};
