import * as React from "react";
import { Dialog, DialogActions, DialogContentText, DialogTitle } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

type AreYouSureDialogProps = {
  onNo: any;
  onYes: any;
  yesButtonType?: "submit" | "button" | "reset" | undefined;
  title: string;
  subTitle?: string;
  open: boolean;
  onClose: () => void;
  header?: React.ReactNode;
};
export const AreYouSureDialog: React.FC<AreYouSureDialogProps> = ({
  open,
  onNo,
  onYes,
  yesButtonType,
  onClose,
  title,
  subTitle,
  header,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {open && (
        <>
          {header && <DialogContent>{header}</DialogContent>}
          <DialogTitle>{title}</DialogTitle>
          {!!subTitle && <DialogContentText className="px-6">{subTitle}</DialogContentText>}
          <DialogContent>
            <DialogActions>
              <Button onClick={onNo} color="primary">
                No
              </Button>
              <Button type={yesButtonType} onClick={onYes} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
